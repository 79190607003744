<template>
  <div style="">
    <!-- padding: 20px; margin: auto; margin-top: 40px; max-width: 850px-->
    <h2>Confirmar Asistencia</h2>

    <!-- enviad / rework() -->

    <!-- intro -->
    <div v-if="enviado && !edit">
      <p v-if="enviado != 'true'">
        Rellenaste el formulario el
        {{
          new Date(enviado).toLocaleDateString() +
          " a las " +
          new Date(enviado).toLocaleTimeString()
        }}. ¡Muchas gracias! <br />
        <br />
        <span class="link" @click="rework()">Editar formulario.</span>
      </p>
      <p v-else>
        Ya has rellenado el formulario. ¡Muchas gracias!
        <span class="link" @click="rework()">Editar formulario.</span>
      </p>
    </div>
    <div v-else>
      <!-- intro -->
      <div>
        <p>
          Para poder ir avanzando poquito a poco, nos gustaría que rellenases el
          formulario con toda la información.
        </p>
        <p>
          Es posible que todavía no sepas con certeza si podrás asistir, pero
          mientras mueves cielo y tierra para conseguirlo, nosotros podemos
          adelantar cosillas con tus datos!!
        </p>

        <p>Si lo prefieres, puedes llamarnos y ya te apuntamos nosotros :)</p>
      </div>

      <!-- button to start filling form -->
      <v-row v-if="!edit">
        <v-col class="mt-2">
          <v-btn large color="#42634b" @click="start()" block dark depressed
            >Confirmar asistencia</v-btn
          >
        </v-col>
      </v-row>

      <div v-if="edit" style="max-width: 600px; margin: auto">
        <!-- form if 1 person -->
        <div v-if="items.length == 1">
          <confirmation-form
            v-model="items[0]"
            :main="true"
          ></confirmation-form>
        </div>
        <!-- form if more than 1 person -->
        <div v-else>
          <v-expansion-panels v-model="panels" light>
            <v-expansion-panel v-for="(item, index) in items" :key="index">
              <v-expansion-panel-header disable-icon-rotate class="px-3 py-0">
                <span v-if="item.name == '' || item.name == null">
                  <i>Nuevo invitado - Rellenar datos</i>
                </span>
                <span v-else>
                  {{ item.name + " " + item.last_name }}
                </span>

                <template v-if="!item.completed" v-slot:actions>
                  <v-icon color="rgb(138, 25, 0)">mdi-alert-circle</v-icon>
                </template>
                <template v-else v-slot:actions>
                  <v-icon color="success">mdi-check</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="padding: 0px 5px !important">
                <div>
                  <confirmation-form
                    v-model="items[index]"
                    :main="index == 0 ? true : false"
                  ></confirmation-form>
                  <div
                    class="link"
                    style="
                      color: rgb(138, 25, 0);
                      padding-top: 15px;
                      text-align: left;
                      width: 100%;
                    "
                    @click="deleteItem(index)"
                  >
                    Borrar {{ item.name }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <!-- links add / delete-->
        <v-row class="mt-2">
          <v-col>
            <span v-if="items.length && false" class="link" @click="addPerson()"
              >Añadir pareja/hijo</span
            >
          </v-col>
          <v-col style="text-align: right">
            <span
              v-if="items.length"
              class="link"
              @click="reset()"
              style="color: rgb(138, 25, 0)"
              >Reinciar</span
            >
          </v-col>
        </v-row>

        <!-- add / submit buttons -->
        <v-row>
          <v-col v-if="items.length">
            <!--<v-btn v-if="!sendEnabled" block color="primary" @click="">Enviar</v-btn> -->

            <v-row>
              <v-col>
                <v-btn block large depressed dark @click="addPerson()"
                  >Añadir pareja/hijo</v-btn
                >
              </v-col>
              <v-col>
                <my-confirm-button
                  v-if="!sendEnabled"
                  :loading="loading"
                  :button_text="
                    'Enviar datos (' +
                    items.length +
                    ' persona' +
                    (items.length != 1 ? 's' : '') +
                    ')'
                  "
                  title="Oups!"
                  text="Por favor, rellena los datos de todos los invitados"
                  text1
                  text2="Volver"
                  button_color="#42634b"
                  @button2="collapseAll()"
                ></my-confirm-button>
                <my-confirm-button
                  v-else
                  :button_text="
                    'Enviar datos (' +
                    items.length +
                    ' persona' +
                    (items.length != 1 ? 's' : '') +
                    ')'
                  "
                  :title="null"
                  :text="
                    'Has apuntado a ' +
                    items.length +
                    ' persona' +
                    (items.length != 1 ? 's' : '') +
                    '.' +
                    '\n¿Estás seguro?'
                  "
                  text1="Cancelar"
                  text2="Enviar"
                  button_color="#42634b"
                  color1="rgb(138, 25, 0)"
                  color2="#42634b"
                  @button2="submitForm()"
                ></my-confirm-button>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-progress-linear
                  v-if="loading"
                  indeterminate
                  color="orange"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div style="font-size: 90%">
        <p><b>Nota: </b>Apunta a todos los que vengáis</p>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationForm from "../components/asistencia/ConfirmationForm";
import MyConfirmButton from "../components/asistencia/MyConfirmButton";
export default {
  components: { ConfirmationForm, MyConfirmButton },
  data() {
    return {
      items: [],
      panels: [],
      fab: false,
      enviado: false,
      edit: false,
      loading: false,
    };
  },
  computed: {
    sendEnabled() {
      var outputt = true;
      this.items.forEach((element) => {
        if (element.completed != true) {
          outputt = false;
        }
      });
      console.log("send enabled: " + outputt);
      return outputt;
    },
    deviceId() {
      var x = localStorage.deviceId;
      if (!x) {
        x = Date.now();
        localStorage.deviceId = x;
      }
      return x;
    },
  },
  watch: {
    items: {
      handler(newValue) {
        if (!this.enviado) {
          //once enviado, we don't update unless enviado
          localStorage.asistencia = JSON.stringify(newValue);
        }
      },
      deep: true,
    },
  },
  methods: {
    start() {
      this.edit = true;
      this.addPerson();
    },
    collapseAll() {
      this.panels = [];
    },
    addPerson() {
      var new_person = {
        completed: false,
        name: "",
        has_alergias: null,
        last_name: "",
        alergias: "",
        bus: undefined,
      };
      this.items.push(new_person);
      this.panels = this.items.length - 1;
    },
    deleteItem(aIndex) {
      this.items.splice(aIndex, 1);
    },
    reset() {
      if (confirm("Seguro que quieres borrarlo todo?")) {
        localStorage.asistencia = [];
        localStorage.removeItem("enviado");
        this.enviado = null;
        this.items = [];
        this.edit = false;
      }
    },
    rework() {
      //wip -> idea is to hide form after submission, but allow users to review if needed.
      this.edit = true;

      //localStorage.enviado = false  //we want to show form, but not to save it as not sent
    },
    async submitForm() {
      this.loading = true;
      var jsonn = {
        tableName: "asistencia",
        apiKey: "FORMULARIO-faeig-lalakdkgh3i-u2ii2u4g",
        device_id: this.deviceId,
        items: this.items,
      };

      var murl =
        "https://lqltgre5qfnsyj2ns7ncg2gs2u0ckujd.lambda-url.eu-west-3.on.aws/";

      let x = await fetch(murl, {
        method: "POST",
        body: JSON.stringify(jsonn),
        headers: {
          "x-api-key": jsonn.apiKey,
        },
      });
      this.loading = false;
      if (x.ok) {
        let myJson = await x.json();
        console.log(myJson);
        var saved = myJson.updatedRows;

        if (saved == this.items.length) {
          alert("Todo enviado! Muchas gracias!");
          this.enviado = new Date().toISOString();
          this.edit = false;
          localStorage.enviado = this.enviado;
          localStorage.asistencia = JSON.stringify(this.items);
        } else {
          alert(
            "Ouch! " +
              (this.items.length - myJson.updatedRows) +
              " han dado error, " +
              saved +
              " se han enviado. Por favor, prueba otra vez"
          );
        }
      } else {
        alert(
          "Ups. Parece que algo ha fallado.\n¿Te importa probar otra vez en un rato? Gracias!"
        );
      }
    },
  },
  mounted() {
    if (localStorage.asistencia) {
      this.items = [];
      this.items = JSON.parse(localStorage.asistencia);
    }

    this.enviado = localStorage.enviado;
  },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0 12px 8px !important;
}
</style>
